.customer-form, .modal-form {
  width: 275px;
  position: absolute;
  top: 195px;
  background: #FFFFFF;
  z-index: 999;
  padding: 0 12px 5px 5px;
  border-radius: 3px;
  border: 1px solid #CCC;

  label {
    margin-top: -8px;
  }

  .form-input {
    margin-top: 15px;
    input {
      border-radius: 3px;
      background: #FFFFFF;
      height: 38px;
      padding: 0 0 0 10px;
    }
  }

  .form-input-error {
    input {
      border-radius: 3px;
      background: #FFFFFF;
      height: 36px;
      padding: 0 0 0 10px;
      border: 2px solid red;
    }
  }
}

.custom-modal {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  padding: 0px 15px 15px 15px;

  .modal-header {
    border-bottom: 1px solid gray;
    height: 50px;
    width: 100%;
    margin-bottom: 10px;

    .modal-title {
      font-weight: 500;
      position: relative;
      top: 15px;
      left: 5px;
    }
  }
}
