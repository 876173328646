.create-article-icon {
  margin-top: 5px;
  margin-left: 3px;
  float: left;
  background: rgb(102, 187, 106);
  padding: 2px;
  font-size: 27px!important;
  color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
}

.create-article-icon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.order-input {
  label {
    margin-top: -8px;
  }

  input {
    border-radius: 3px;
    background: #FFFFFF;
    height: 38px;
    padding: 0 0 0 10px;
  }
}

.order-input-error {
  input {
    border-radius: 3px;
    background: #FFFFFF;
    height: 38px;
    padding: 0 0 0 10px;
    border: 2px solid red;
  }
}
