.small-card {
  .MuiCardHeader-subheader {
    font-size: 13px;
    margin-left: 2px;
  }
}

.small-card-double {
  .MuiCard-root {
    width:  100%;
    float: left;
    height: 51.5px;
  }
  .MuiCardHeader-root {
    width: 100%;
    padding: 10px 16px 16px 16px;
  }
  .MuiCardHeader-title {
    float: left;
  }
  .MuiCardHeader-subheader {
    font-size: 13px;
    float:  right;
  }
}
