.document-category-container {
  display: flex;
  flex-wrap: wrap;
  & > * {
    padding: 20% 0 0 0;
    width: 250px;
    height: 150px;
    font-size: 14px;
  }
}
