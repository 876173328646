body {
  background: #EEE;
}

.App {
  margin: 0 auto;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
