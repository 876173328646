.loading {
  padding: 0 20px;
  color:  #ccc;
  input, label, span, textarea {
    color:  #ccc;
  }
}

.send-invoice-body {
  padding: 0 20px;
}
