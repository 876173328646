.invoice-payment {
  margin: 0;
  padding: 0;
}

.invoice-payment-form-container {
  background: #e5e5e5;
  position: absolute;
  right: 5px;
  margin-top: 40px;
  padding: 10px;
  height: 50px;
  width: 300px;
  border-radius: 3px;
  z-index: 9;
}

.invoice-payment-icon, .invoice-edit-icon {
  float: left;
  font-size: 36px;
  color: #FFFFFF;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
}

.invoice-payment-icon {
  background: rgb(102, 187, 106);
}

.invoice-action-icon {
  color: #FFFFFF;
  float: right;
  margin-right: 3px;
  border-radius: 3px;
  cursor: pointer;
}

.invoice-payment-text {
  float: left;
  padding:  2px 5px 0 3px;
  background: rgb(102, 187, 106);
  color: #FFFFFF;
  height: 23px;
  border-radius: 0 3px 3px 0;
}

