.file-validation-error {
  margin-top: 50px;
  width: 100%;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #ef5350;
  }
}

.file-preview-container {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;

  .file-preview-row {
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
  }
}


.custom-modal {
  .modal-body {
    label.choose-file-label {
      cursor: pointer;
      padding:  10px;
      border-radius:  3px;
      border:  1px solid #000000;
    }
  }
}
