.dashboard-container {
  width: 98%;
  margin: 20px auto;

  .small-card-icon {
    font-size: 100px;
  }

  .balance-icon {
    color: linear-gradient(60deg, #ef5350, #e53935);
  }

  .revenue-icon {
    color: linear-gradient(60deg, #ffa726, #fb8c00);
  }
}
