.navbar {
  margin-bottom: 90px;

  a {
    text-decoration: none;

    h6 {
      color: #FFFFFF;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .navbar-links {
    margin-left: 25px;

    a {
      cursor: pointer;
      color: #FFFFFF;
      margin-left: 20px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.menu-right-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
