.order-form-container {
  height: auto;
  margin: 20px 0 20px 0;
  background: #e5e5e5;
  padding: 0 10px 0 10px;
  border-radius: 5px;

  .customer-settings-icon {
    margin: 3px 0 0 3px;
    float: left;
    background: rgb(102, 187, 106);
    font-size: 36px;
    color: #FFFFFF;
    border-radius: 3px;
    cursor: pointer;
  }

  .update-customer-icon {
    font-size: 25px;
    position: absolute;
    right: 5px;
  }
}

.update-article-content {
  .invoice-input {
    margin-top: 20px;
  }
}
