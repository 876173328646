@import '../styles/colors.scss';

.login-form-container {
  width: 100%;
  margin: 0 auto;
  height: 400px;
  padding: 20px;
  background: #3568fe;
  border-radius: 7px;

  h1 {
    color: #FFFFFF;
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    background: #36c535;
    color: #FFFFFF;
    text-transform: none;
    font-weight: bold;
    font-size: 16px;

    &:hover {
      background: #38d637;
    }
  }

  input {
    background: #FFFFFF;
    border-radius: 5px;
  }

  form  {
    p {
      color: #FFFFFF;
    }
  }
}
