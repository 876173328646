.invoice-input {
  label {
    margin-top: -8px;
  }

  input {
    border-radius: 3px;
    background: #FFFFFF;
    height: 38px;
    padding: 0 0 0 10px;
  }
}

.invoice-input-error {
  input {
    border-radius: 3px;
    background: #FFFFFF;
    height: 38px;
    padding: 0 0 0 10px;
    border: 2px solid red;
  }
}
